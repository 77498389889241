<template>
  <el-container class="layout__wrap">
    <el-header class="header__wrap" height="90px">
      <div class="logo__wrap">
        <img class="logo" src="@/assets/img/logo-title.png" alt="">
        <div class="J_menutoggle" @click="toggleColl">
          <el-tooltip
            effect="dark"
            :content="isCollapse ? `展开` : `收起`"
            placement="bottom"
          >
            <i class="el-icon-menu" color="#fff"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="avatar__wrap">
        <el-avatar class="avatar" :src="require('@/assets/img/default.png')"></el-avatar>
        <el-popover
          placement="bottom"
          width="80"
          trigger="click">
          <div style="text-align:center;cursor: pointer;" @click="logout">退出登录</div>
          <div class="title" slot="reference">{{userName}}<i class="el-icon-caret-bottom" color="#fff"></i></div>
        </el-popover>
        
      </div>
    </el-header>
    <el-container style="overflow-y:scroll;">
      <NaviBar :isCollapse="isCollapse"></NaviBar>
      <el-main class="layout__main">
        <page-header></page-header>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import PageHeader from './PageHeader.vue';
import NaviBar from './NaviBar';
import { getStorage, clear } from "@/storage"
export default {
  name: 'LayoutContainer',
  components: {
    NaviBar,
    PageHeader
  },
  data() {
    return {
      isCollapse: false,
      toggleMenu: false,
      userName: getStorage('userName')
    }
  },
  created() {
    this.getBaseInfo()
  },
  methods: {
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/admin/logout',{params: {}}).then(res => {
              // clear(); // 删除token
              // window.location.reload();
              window.location.assign('/login')
              // this.$router.push('/login');
              this.$store.commit('setIsLogin', false)
            })
            done()
          } else {
            done()
          }
        }
      })
    },
    toggleColl() {
      this.isCollapse = !this.isCollapse;
      this.toggleMenu = !this.toggleMenu;
    },
    // 获取公用基础数据
    getBaseInfo() {
      // 获取开票公司列表
      this.$http.post('/admin/InvoiceCompany/list', {params:{}}).then(res => {
        if(res.code === 1) {
          this.$store.commit("setInvoiceCompany", res.data.list);
        } else {
          this.$message.error(res.msg)
        }
      })

      //获取企业列表
      this.$http.get('/admin/company/searchList',{params:{}}).then(res => {
        if(res.code) {
          this.$store.commit("setCompanyList", res.data)
        } else {
          this.$message.eroor(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.layout__wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
  .header__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme;
    // background-image: linear-gradient(to right, $theme, #0bCFFF);
    .logo__wrap {
      display: flex;
      align-items: center;
      .logo {
        display: block;
        width: 50%;
      }
      .J_menutoggle {
        color: #fff;
        margin-left: 20px;
      }
    }
    .avatar__wrap {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 10px;
      }
      .title {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        width: 100%;
        text-align: center;;
      }
      
      ::v-deep .el-popover {
        min-width: 80px;
      }
    }
  }
  .layout__main {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #f5f5f5;

    .page-header__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      color: #595959;

      .title {
        height: 58px;
        line-height: 58px;
        
      }
    }

    &>div {
      flex: 1;
      min-height: 0;
      overflow-y: scroll;
    }

  }
}
</style>
