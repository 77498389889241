import axios from "axios"
import { getStorage, clear } from "@/storage"
import { Message } from "element-ui"
import { sign } from "@/util/sign.js"

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// 添加请求拦截器
http.interceptors.request.use(function (req) {
  console.log(req)
  if(req.method == 'get'){
    req.data = req.params;
  }else{
    req.data.timestamp = new Date().getTime();
    if(req.data.type == 'upload'){
      req.data.formData.append('sign',sign({timestamp:req.data.timestamp, ...req.data.obj}));
      req.data.formData.append('timestamp', req.data.timestamp);
      req.data = req.data.formData;
    }
  }
  req.data.sign = sign(req.data);
  req.headers['Content-Type'] = "application/json; charset=utf-8"
  // 添加时间戳和签名

  req.headers.token = getStorage("token")
  return req;

})

// 添加响应拦截器
http.interceptors.response.use(function (res) {
  const {code, msg, data} = res.data
  // code为 -10000， token过期
  if(code === '-10000') {
    clear() // 如果token过期，就清除token
    return Promise.reject(res.data)
  } else{
    return res.data
  }
}, function (res) {
  console.log(res, 888)
  Message.error("请求失败");
  return Promise.reject(res.data)
})

export default http
