import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: "/company",
    name: "Company",
    redirect: "/company",
    meta: {
      title: "用工单位",
      icon: require('@/assets/img/nav/1.png'),
      alias: 'company'
    },
    component: Layout,
    children: [
      {
        path: "/company/index",
        name: "CompanyIndex",
        meta: {
          title: "用工单位列表",
          parent: '用工单位'
        },
        component: () => import("@/views/company/index")
      }
    ]
  },
  {
    path: "/insurance",
    name: "Insurance",
    meta: {
      title: "五险一金",
      icon: require('@/assets/img/nav/2.png'),
      alias: 'insurance'
    },
    component: Layout,
    children: [
      {
        path: "/insurance/declareDetails",
        name: "DeclareDetails",
        meta: {
          title: "申报明细",
          parent: '五险一金'
        },
        component: () => import("@/views/insurance/declareDetails/index")
      },
      {
        path: "/insurance/payDetails",
        name: "PayDetails",
        meta: {
          title: "缴纳明细",
          parent: '五险一金'
        },
        component: () => import("@/views/insurance/payDetails/index")
      },
      {
        path: "/insurance/insuranceLevel",
        name: "InsuranceLevel",
        meta: {
          title: "五险基数",
          parent: '五险一金'
        },
        component: () => import("@/views/insurance/insuranceLevel/index")
      },
      {
        path: "/insurance/fundLevel",
        name: "FundLevel",
        meta: {
          title: "公积金比例",
          parent: '五险一金'
        },
        component: () => import("@/views/insurance/fundLevel/index")
      }
    ]
  },
  {
    path: "/staff",
    name: "Staff",
    meta: {
      title: "员工管理",
      icon: require('@/assets/img/nav/4.png'),
      alias: 'employee'
    },
    component: Layout,
    children: [
      {
        path: "/staff/index",
        name: "StaffIndex",
        meta: {
          title: "员工管理列表",
          parent: '员工管理'
        },
        component: () => import("@/views/staff/index")
      },
      {
        path: "/staff/contract",
        name: "StaffContract",
        meta: {
          title: "员工合同",
          parent: '员工管理'
        },
        component: () => import("@/views/staff/contract/index")
      },
      {
        path: "/staff/leave",
        name: "StaffLeave",
        meta: {
          title: "离职申请",
          parent: '员工管理'
        },
        component: () => import("@/views/staff/leave/index")
      },
      {
        path: "/staff/notify",
        name: "StaffNotify",
        meta: {
          title: "信息变动通知",
          parent: '员工管理',
          type: '1', // type:1 (总管理员端不展示)
        },
        component: () => import("@/views/staff/notify/index")
      },
      {
        path: "/staff/apply",
        name: "StaffApply",
        meta: {
          title: "更改申请",
          parent: '员工管理',
          type: '1', // type:1 (总管理员端不展示)
        },
        component: () => import("@/views/staff/apply/index")
      }
    ]
  },
  {
    path: "/finance",
    name: "Finance",
    meta: {
      title: "财务管理",
      icon: require('@/assets/img/nav/3.png'),
      alias: 'finance'
    },
    component: Layout,
    children: [
      {
        path: "/finance/wageSettlement",
        name: "WageSettlementIndex",
        meta: {
          title: "工资结算",
          parent: '财务管理',
          role: 0,
        },
        component: () => import("@/views/finance/wageSettlement/index")  // 分公司账号显示
      },
      {
        path: "/finance/wageSettlementHQ",
        name: "WageSettlementHQIndex",
        meta: {
          title: "工资结算",
          parent: '财务管理',
          role: 1
        },
        component: () => import("@/views/finance/wageSettlementHQ/index")  // 总管理账号显示
      },
      {
        path: "/finance/salaryBar",
        name: "SalaryBar",
        meta: {
          title: "工资条",
          parent: "财务管理"
        },
        component: () => import("@/views/finance/salaryBar/index")
      },
      {
        path: "/finance/replenish",
        name: "Replenish",
        meta: {
          title: "补发列表",
          parent: '财务管理'
        },
        component: () => import("@/views/finance/replenish/index")
      },
      {
        path: "/finance/repaid",
        name: "Repaid",
        meta: {
          title: "垫付列表",
          parent: '财务管理'
        },
        component: () => import("@/views/finance/repaid/index")
      },
      {
        path: "/finance/billingInfo",
        name: "BillingInfoIndex",
        meta: {
          title: "开票信息",
          parent: '财务管理'
        },
        component: () => import("@/views/finance/billingInfo/index")
      }
    ]
  },
  {
    path: "/timecard",
    name: "timecard",
    meta: {
      title: "考勤管理",
      icon: require('@/assets/img/nav/5.png'),
      alias: ''
    },
    component: Layout,
    children: [
      {
        path: "/timecard/set",
        name: "TimecardSet",
        meta: {
          title: "考勤设置",
          parent: '考勤管理'
        },
        component: () => import("@/views/timecard/set/index")
      },
      {
        path: "/timecard/record",
        name: "TimecardRecord",
        meta: {
          title: "考勤记录",
          parent: '考勤管理'
        },
        component: () => import("@/views/timecard/record/index")
      }
    ]
  },
  {
    path: "/accountNumber",
    name: "AccountNumber",
    meta: {
      title: "账号管理",
      icon: require('@/assets/img/nav/5.png'),
      alias: ''
    },
    component: Layout,
    children: [
      {
        path: "/accountNumber/index",
        name: "accountNumberIndex",
        meta: {
          title: "账号管理列表",
          parent: '账号管理'
        },
        component: () => import("@/views/accountNumber/index")
      }
    ]
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]



