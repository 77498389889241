import Vue from 'vue'
import Vuex from 'vuex'
// import http from "@/api/http"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin:false,
    subUnitSize: 'mini',
    roleTypeObj: {
      1: '管理员',
      2: '员工'
    },
    unit: [],
    invoiceCompany: [], // 开票公司
    companyList: [], // 企业列表
    access: {
      company: '',
      insurance: '',
      employee: '',
      finance: ''
    },
    icId: '',
    routes:[]
  },
  mutations: {
    setUnit: (state, payload) => state.unit = payload,
    setInvoiceCompany: (state, payload) => state.invoiceCompany = payload,
    setCompanyList: (state, payload) => state.companyList = payload,
    setIsLogin(state, payload){
      state.isLogin = payload;
    },
  },
  actions: {
  },
  getters: {
    invoiceCompany: state => state.invoiceCompany,
    companyList: state => state.companyList,
    
    unit: state => state.unit,
    routesArr: state => state.routes,
  },
  modules: {
  },
})
