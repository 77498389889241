<template>
  <el-aside width="auto">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#0583FF"
    >
      <el-submenu
        v-for="item in routes"
        :key="item.name"
        :index="item.path">
        <template>
          <template slot="title">
            <!-- <i class="el-icon-menu"></i> -->
            <el-image
              class="menu-icon"
              style="width: 20px; height: 20px"
              :src="item.meta.icon"
              fit="cover"></el-image>
            <span>{{ item.meta.title }}</span>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <!-- icId == 0 && child.meta.type == 1 总管理员账号不展示信息变动菜单 -->
              <el-menu-item
                :key="child.name"
                :index="child.path"
                :class="(icId == 0 && child.meta.type == 1) ? 'hidden' : ''" 
              >
                <!-- 信息变动通知菜单，有新通知加红点 -->
                <el-badge v-if="child.meta.type == 'notify' && change_notify > 0 " is-dot class="item">
                  <span>{{ child.meta.title }}</span>
                </el-badge>
                <span v-else>{{ child.meta.title }}</span>
              </el-menu-item>
              
            </template>
          </el-menu-item-group>
        </template>
      </el-submenu>
    </el-menu>

  </el-aside>
</template>
<script>
import {getStorage} from '@/storage'
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      routes: [],
      change_notify: 0,
      icId: Number(getStorage('icId')),  // 0 总公司端，2鹰潭分公司
    }
  },
  watch:{
    $route(to,from){
      console.log(from.path) // 从哪来
      console.log(to.path) // 到哪去
      if(to.path == '/staff/notify') {
        this.getMsg();
      }
    }
  },
  created() {
    // 分公司才调是否有信息变动接口
    if(this.icId != 0) {
      this.getMsg();
      setInterval(() => {
        this.getMsg();
      },200000)
    }
  },
  mounted() {
    let _icId = Number(getStorage('icId'))
    this.routes = this.$router.options.routes.slice(2, this.$router.options.routes.length - 1)
    let accessObj = getStorage('access')

    let deleteKey = []
    for(let v in this.routes) {
      if(accessObj[this.routes[v].meta.alias] === 0) {
        deleteKey.push(v)
      } else {
        if(this.routes[v].meta.alias === 'finance') {
          if(_icId === 0) {
            this.routes[v].children.splice(0,1);
          } else {
            this.routes[v].children.splice(1,1);
          }
        } 
      }
    }
    if (deleteKey.length > 0) {
      let i = 0;
      for(let k in deleteKey) {{
        this.routes.splice(deleteKey[k] - i,1);
        i ++
      }}
    }
  },
  methods: {
    getMsg() {
      this.$http.get('/admin/msg/index',{params:{}}).then(res => {
        if(res.code == 1) {
          this.change_notify = res.data.change_notify;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 201px;
  min-height: 400px;
}
.menu__wrap ::v-deep .el-badge__content.is-fixed.is-dot {
  top: 10px;
}
.hidden {
  display: none;
}
</style>
