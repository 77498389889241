import Vue from 'vue'
import VueRouter from 'vue-router'
import {commonRouter} from "./routes"
import {getStorage} from '@/storage'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: commonRouter
})

router.beforeEach((to, from, next) => {
  let token = getStorage('token')
  if(token){
    next()
  } else {
    to.path === "/login" ? next() : next('/login')
  }
})

export default router
